<template>
  <div class="container basic-modal-layout">
    <k-resize-observer
      class="header-outer row px-2"
      @on-resize-rect="OnResizeModalHeader"
    >
      <slot name="header"></slot>
    </k-resize-observer>
    <div class="body-outer row">
      <div class="w-100" :style="bodyHeight">
        <slot name="body"></slot>
      </div>
    </div>
    <k-resize-observer
      class="footer-outer row pl-2 pr-3"
      @on-resize-rect="OnResizeModalFooter"
    >
      <slot name="footer"></slot>
    </k-resize-observer>
  </div>
</template>

<script>
export default {
  name: 'basic-modal-layout',
  data() {
    return {
      modalHeaderHeight: 0,
      modalFooterHeight: 0,
    };
  },
  methods: {
    OnResizeModalHeader(e) {
      this.modalHeaderHeight = e.height;
    },
    OnResizeModalFooter(e) {
      this.modalFooterHeight = e.height;
    },
  },
  computed: {
    bodyHeight() {
      let sum = this.modalHeaderHeight + this.modalFooterHeight;
      return `max-height: calc(100vh - 132px - ${sum}px); overflow-y: scroll; overflow-x: hidden;`;
    },
  },
};
</script>
